@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	scroll-behavior: smooth;
}

.dashed-border {
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%238F8E8A' stroke-width='3' stroke-dasharray='6' stroke-dashoffset='15' stroke-linecap='round'/%3e%3c/svg%3e");
	border-radius: 30px;
}

input[type="radio"] {
	appearance: none;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	display: grid;
	place-content: center;
	outline: 1px solid #8f8e8a;
	cursor: pointer;
}

input[type="radio"]::before {
	content: "";
	width: 14px;
	height: 14px;
	border-radius: 50%;
	transform: scale(0);
	transition: 200ms transform ease-in-out;
	box-shadow: inset 14px 14px #aa6fb6;
}

input[type="radio"]:checked::before {
	transform: scale(1);
}

.prose ul {
	@apply dash-list;
}

.dash-list > li {
	position: relative;
	padding-left: 1em;
}

.dash-list > li::before {
	content: "–"; /* endash */
	position: absolute;
	left: 0;
	top: 0;
}

.responsive-object {
	position: relative;
}

.responsive-object iframe,
.responsive-object object,
.responsive-object embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.link-underline {
	@apply hover:underline underline-offset-2;
}

html {
	@apply tracking-wider;
}

h1,
h2,
h3,
h4,
button,
a,
header,
footer,
form {
	@apply tracking-tight font-work;
}

h1.font-bold,
h2.font-bold,
h3.font-bold,
h4.font-bold,
button.font-bold,
a.font-bold,
header.font-bold,
footer.font-bold,
form.font-bold {
	@apply tracking-wide font-work;
}
